<template>
  <div v-if="assignedQuestion === 'yes'">
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-3">Critical Thinking Question</h2>

      <p class="mb-4">
        This is a "Critical Thinking" question. You will be scored more on your thought process than
        your answer, so the key is to write, write, write. Explain the questions you have and how
        you would answer them. Who would you look to for answers (sources)? Who would you believe?
        Make the connections you can make, but most of all explain your thought process.
      </p>

      <p class="mb-4">
        Two or three paragraphs should be sufficient; one or two sentences will surely not be
        sufficient. This question could take up to a half-hour to answer completely.
      </p>

      <p class="mb-4">
        Chemical kinetics describes the speed at which chemical reactions happen, while
        thermodynamics is about the relative stability of the reactants and products at equilibrium.
        The Haber process describes the formation of ammonia which is used as a fertilizer:
      </p>

      <p class="pl-6 mb-5">
        <chemical-latex content="N2(g) + 3 H2(g) <=> 2 NH3(g)" />
      </p>

      <p class="mb-4">
        For the forward reaction,
        <stemble-latex content="$\Delta\text{H}^\circ_\text{rxn}\,=\,-92\,\text{kJ/mol}$" />
        (indicating an exothermic reaction) and
        <stemble-latex
          content="$\Delta\text{S}^\circ_\text{rxn}\,=\,-199\,\text{J/K}\,\text{mol}.$"
        />
        This results in
        <stemble-latex content="$\Delta\text{G}^\circ_\text{rxn}\,=\,-32.7\,\text{kJ/mol}$" /> at
        298 K. This indicates that the forward reaction is spontaneous thermodynamically at room
        temperature and pressure but its reaction rate is so slow that, in reality, virtually no
        ammonia is made. In order to generate ammonia, the temperature and pressure must be
        increased substantially and a catalyst (iron filings) must be used. A catalyst is a
        substance that is not consumed in the course of the reaction and speeds up the reaction by
        providing an alternative reaction pathway(s) that have lower activation energies. The below
        graph shows the impact of increasing the temperature and pressure on the percent yield of
        ammonia. Below <stemble-latex content="$350\,^\circ\text{C,}$" /> the reaction rate is so
        slow that no appreciable product forms.
      </p>

      <p class="mb-5">
        What happens to the yield as the temperature is increased? What about the pressure? Using Le
        Chatelier's Principle and the fact that this is an exothermic reaction, explain the trend
        that is seen in the figure below.
      </p>

      <p class="pl-4 mb-3">
        <v-img :src="imageName" style="max-width: 456px" />
      </p>

      <p class="mb-0">
        <s-textarea v-model="inputs.studentAnswer" outlined />
      </p>
    </v-form>
  </div>

  <div v-else>
    <h3 style="color: #808080; text-align: center" class="mt-13">
      Sorry, but this is not one of your 3 assigned questions.
    </h3>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'OleMiss116_PL11_Q4',
  components: {ChemicalLatex, STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/haberBoschYieldVsTandP.png';
    },
    lastName() {
      return this.$inertia.getLastName().toUpperCase();
    },
    assignedQuestion() {
      if (
        !this.lastName.startsWith('A') &&
        !this.lastName.startsWith('B') &&
        !this.lastName.startsWith('C') &&
        !this.lastName.startsWith('D') &&
        !this.lastName.startsWith('E') &&
        !this.lastName.startsWith('F') &&
        !this.lastName.startsWith('G') &&
        !this.lastName.startsWith('H') &&
        !this.lastName.startsWith('I') &&
        !this.lastName.startsWith('J') &&
        !this.lastName.startsWith('K') &&
        !this.lastName.startsWith('L') &&
        !this.lastName.startsWith('M') &&
        !this.lastName.startsWith('N') &&
        !this.lastName.startsWith('O') &&
        !this.lastName.startsWith('P') &&
        !this.lastName.startsWith('Q') &&
        !this.lastName.startsWith('R')
        // !this.lastName.startsWith('S') &&
        // !this.lastName.startsWith('T') &&
        // !this.lastName.startsWith('U') &&
        // !this.lastName.startsWith('V') &&
        // !this.lastName.startsWith('W') &&
        // !this.lastName.startsWith('X') &&
        // !this.lastName.startsWith('Y') &&
        // !this.lastName.startsWith('Z')
      ) {
        return 'yes';
      } else {
        return 'no';
      }
    },
  },
};
</script>
